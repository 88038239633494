<template>
    <div class="login"  :class="{'login--disabled':!agree}">
        <div class="title-panel">
            <span class="title">登录</span>
            <span class="register-jumper">
                还没有账号？
                <!-- <span class="redWords" @click="$router.push('/register')">立即注册 ></span> -->
                <span class="redWords" @click="toggleRegisterPanel">立即注册 ></span>
            </span>
        </div>

        <div class="error-tiper" v-if="loginFailed">手机号或者密码错误</div>

        <div style="position: relative;">
            <a-input size="large" placeholder="请输入手机号" v-model="userName" ref="userNameInput" @input="userNameInput">
                <img src="../../assets/icon/phone.png" class="icon" slot="prefix" />
            </a-input>
            <p class="error" v-if="userNameError">手机号错误</p>
        </div>
        <div style="position: relative;">
            <a-input-password
                size="large"
                placeholder="请输入密码"
                type="password"
                v-model="password"
                ref="passwordInput"
                @pressEnter="login"
                @input="passwordInput"
            >
            <img src="../../assets/icon/password.png" class="icon" slot="prefix" />
            </a-input-password>
            <p class="error" v-if="passwordError">请输入密码</p>
        </div>
        <div class="center">
            <!-- <a-checkbox v-model="remeber">记住密码</a-checkbox> -->
            <div class="right" @click='forgetPassword'>忘记密码</div>
        </div>

        <button class="default-btn" @click="login">
            <a-icon type="loading" v-show="loading"/>
            <span v-show="!onLine">离线登录</span>
            <span v-show="onLine">登录</span>
        </button>
        <button class="default-btn login2" @click="login2" v-show="onLine">之江汇快捷登录</button>
        <div v-if="false" class="checkbox" @click="agree=!agree">
            <img src="@/icons/svg/sel_pc.svg" v-show="agree">
            <img src="@/icons/svg/unsel_pc.svg" v-show="!agree">
            我已阅读并同意<a @click.stop href="./user.pdf" target="_black">《用户须知》</a>
        </div>

        <a-modal :visible="visible" :footer="null" :closable="false" :width="392">
            <div class="completed">
                <img src="../../assets/img_login_message.png" />
                <p>您的个人信息不完善，请先完善个人信息。</p>
                <!-- <button class="default-btn" @click="$router.push('complete')">确定</button> -->
                <button class="default-btn" @click="toggleCompletePanel">确定</button>
<!--              <button class="default-btn" v-if="$route.query.redirectUrl" @click="toClassServer({replace: true, redirectUrl:$route.query.redirectUrl})">直接跳转课后服务平台</button>-->
            </div>
        </a-modal>
    </div>
</template>

<script>
import md5 from "blueimp-md5";
import {login,getLoginUrl,completed} from "../../api/user"
import {mapActions, mapMutations} from "vuex";
import Cookies from 'js-cookie'
export default {
    data() {
        return {
            agree:true,
            userName: null,
            password: null,
            remeber: false,
            visible: false,
            passwordError:false,
            userNameError:false,
            loading:false,
            loginFailed:false,
            src:'',
            userId: undefined,
            role: '',
            schoolName: ''
        };
    },
    computed:{
        onLine(){
            return !this.offline
        }
    },
    props: ["changeForm","offline","zjer"],
    mounted: function() {
        //localStorage.user = ''
        if (localStorage.userName) {
        this.userName = localStorage.userName;
        this.password = localStorage.password;
        this.remeber = true
        }
    },
    methods: {
        ...mapMutations(["setUser"]),
      ...mapActions(['toClassServer']),
        // 切换到完善信息面板
        toggleCompletePanel() {
            this.$emit('toggleCompletePanel', this.userId,this.role, this.schoolName)
            this.visible = false
        },
        // 切换到注册面板
        toggleRegisterPanel() {
            // this.$emit('toggleLoginPanel', 0)
            this.$router.push({ path: '/loginRegistration', query: {loginStep: 0,redirect:this.$route.query.redirect}})
        },
        // 切换到忘记密码面板
        forgetPassword() {
            // this.$emit('toggleLoginPanel', 3)
            this.$router.push({ path: '/loginRegistration', query: {loginStep: -1,redirect:this.$route.query.redirect}})

        },
        userNameInput(e) {
            let value = e.target.value
            let reg = /^1[3456789]\d{9}$/
            this.userNameError = !reg.test(value)
        },
        passwordInput(e) {
            let value = e.target.value
            let reg = /^.{6,18}$/
            this.passwordError = !reg.test(value)
        },
        login() {
        if(!this.agree) return
        if(this.userNameError || this.passwordError || this.loading) return
        if (this.userName && this.password) {
            this.loading = true
            login({
                userName: this.userName,
                password: md5(this.password).toUpperCase(),
            })
            .then(res => {
                if (res) {
                localStorage.lastUser = JSON.stringify({
                    userName: this.userName,
                    password: this.password
                })
                localStorage.user = JSON.stringify(res.data);
                this.setUser(res.data);
                Cookies.set('commonName', localStorage.user, { expires: 7, domain: 'zjeav.com' })
                if (this.remeber) {
                    localStorage.userName = this.userName;
                    localStorage.password = this.password;
                } else {
                    localStorage.userName = "";
                    localStorage.password = "";
                }
                // console.log('userInfo======>', res.data)
                this.userId = res.data.userId
                this.role = res.data.roles[0]
                this.schoolName = res.data.schoolName
                completed(res.data.userId).then(res => {
                    if (!res.data) {
                    this.visible = true;
                    } else {
                    // this.$router.push("/");
                      if(this.$route.query.redirectUrl){
                        this.toClassServer({replace: true, redirectUrl:this.$route.query.redirectUrl})
                      }else{
                        this.$router.push(this.$route.query.redirect || '/');
                      }
                    }
                }).catch(e => {
                    this.loading = false
                    this.loginFailed = true
                });
                }
            })
            .catch(e => {
                console.log(e);
                this.loading = false
                this.loginFailed = true
            });
        }
        },
        login2(){
			  if(!this.agree)return
        this.webview = document.querySelector('webview');
        getLoginUrl().then((res)=>{
            location.href = res.data;
        })
        }
    }
}
</script>

<style lang="less" scoped>
    .checkbox {
      margin-top: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      color: #666666;
      img {
        margin-right: 8px;
      }
      a {
        color: #F6492B;
      }
    }

    .log {
        height: calc(~"100% - 80px");
        display: flex;
        &_logo {
            width: 730px;
            height: 100%;
            background: url("../../assets/login_img@2x.png");
            background-size: 100% 100%;
        }
        /deep/ .ant-input {
            height: 48px;
        }
    }
    .completed {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
        width: 173px;
        height: 173px;
        }
        p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        }
        .default-btn {
        width: 224px;
        margin-top: 40px;
        }
    }
    .login {
        display: flex;
        align-self: center;
        flex-direction: column;
        width: 336px;

        &--disabled {
          .default-btn {
            pointer-events: none;
            opacity: .3;
          }
        }
        .title-panel {
            display: flex;
            justify-content: space-between;
            .title {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #333333;
            letter-spacing: 0;
            line-height: 24px;
            }
            .register-jumper {
                font-family: PingFangSC-Regular;
                font-size: 16px;
                color: #666666;
                letter-spacing: 0;
                .redWords {
                    color: #f73210;
                    cursor: pointer;
                }
            }
        }

        .error-tiper {
            background: #f5ccc5;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #F6492B;
            letter-spacing: 0;
            line-height: 16px;
            padding: 8px;
            margin-top: 32px;
        }
        .icon {
        width: 16px;
        height: 16px;
        }
        /deep/ .ant-input-affix-wrapper {
        margin-bottom: 12px;
        margin-top: 24px;
        }
        .error {
        font-size: 12px;
        color: #ed4014;
        position: absolute;
        }
        .center {
        margin-bottom: 10px;
        .right {
            float: right;
            cursor: pointer;
        }
        /deep/ .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f73210;
            border-color: #f73210;
        }
    }
        p.bottom {
            font-size: 16px;
            margin-top: 40px;
            color: rgba(0, 0, 0, 0.45);
            .black {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            font-weight: 600;
        }
        .right {
            float: right;
            text-decoration: underline;
            cursor: pointer;
        }
        }
    }
    .default-btn{
        &.login2{
            border:1px solid #f73210;
            background: white;
            color: #f73210;
            // position: absolute;
            width: 336px;
            margin-top: 30px;
        }
    }
    .default-btn {
        height: 48px;
        background: #f73210;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        border: 0;
        cursor: pointer;
        -webkit-app-region: no-drag;
    }
    /deep/.ant-input {
            height: 48px;
        }

</style>
