<template>
  <a-form :form="form" class="Complete">
    <p class="title">
        完善个人信息
      </p>
    <div :class="{isRequire:!showTitle&&nameRequired}">
      <a-form-item :label="showTitle ? '姓名' : ''">
        <!-- <span class="required-word">必填</span> -->
        <a-input
          placeholder="请输入姓名"
          v-decorator="['name', {trigger: 'blur',
        rules: [(nameRequired?{ required: true, message: '请输入姓名!'}:{}),{message: '请输入正确姓名!',pattern:/^[\u4E00-\u9FA5]{2,6}$/ }] }]"
        >
          <img src="../assets/icon/user.png" class="icon" slot="prefix" />
        </a-input>
      </a-form-item>
    </div>
    <div :class="{isRequire:!showTitle&&idCardNoRequired}">
      <a-form-item :label="showTitle ? '身份证号' : ''">
        <!-- <span class="required-word">必填</span> -->
        <a-input
          placeholder="请输入身份证号"
          v-decorator="['idCardNo', {trigger: 'blur',
        rules: [(idCardNoRequired?{ required: true, message: '请输入身份证号!'}:{}),{ message: '请输入正确身份证号!',pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/ }] }]"
        >
          <img src="../assets/icon/idcard.png" class="icon" slot="prefix" />
        </a-input>
      </a-form-item>
    </div>
    <div :class="{isRequire:!showTitle&&areaRequired}" class="area">
      <!-- <span class="required-word">必填</span> -->
      <a-form-item :label="showTitle ? '区域' : ''">
        <img src="../assets/icon/area.png" class="icon absolute" />
        <a-cascader
          v-decorator="['address',{rules:areaRequired ? [{ required: true, message: '请选择地区!'}] : []}]"
          :options="areaList"
          placeholder="请选择所在区域"
        />
      </a-form-item>
    </div>
    <div :class="{isRequire:!showTitle&&schoolNameRequired}">
      <a-form-item :label="showTitle ? '学校' : ''">
        <!-- <span class="required-word">必填</span> -->
        <a-input
          placeholder="请输入学校"
          v-decorator="['schoolName', {trigger: 'blur', rules: [(schoolNameRequired?{ required: true, message: '请输入学校!'}:{}),{ message: '请输入正确学校!' ,pattern:/^[\u4E00-\u9FA5]{4,}$/}] }]"
        >
          <img src="../assets/icon/school.png" class="icon" slot="prefix" />
        </a-input>
      </a-form-item>
    </div>
    <a-form-item :label="showTitle ? '年级' : ''">
      <img src="../assets/icon/grade.png" class="icon absolute" />
      <a-select
        placeholder="请选择年级"
        style="height: 48px"
        v-decorator="['gradeCode']"
        :dropdownStyle="{'-webkit-app-region':'no-drag'}"
      >
        <a-select-option v-for="grade in gradeList" :key="grade.gradeCode">{{ grade.gradeName }}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-if="role==='teacher'" :label="showTitle ? '学科' : ''">
      <img src="../assets/icon/subject.png" class="icon absolute"/>
      <a-select
        placeholder="请选择学科"
        style="height: 48px"
        v-decorator="['subjectCode']"
        :dropdownStyle="{'-webkit-app-region':'no-drag'}"
      >
        <a-select-option v-for="subject in subjectList" :key="subject.subjectCode">{{ subject.subjectName }}</a-select-option>
      </a-select>
    </a-form-item>
<!--    <button class="cancel-btn" @click="$router.push('/')" v-show="showTitle">取消</button>-->
    <button class="default-btn" @click="submit">
      <a-icon type="loading" v-show="loading" />保存
    </button>
  </a-form>
</template>
<script>
import areaList from "./area";
import {getGrade,getSubject,getuserConfig,complete,getUser} from "../api/user"
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  data: function () {
    return {
      form: this.$form.createForm(this, { name: "complete" }),
      gradeList: [],
      subjectList:[],
      areaList: areaList,
      nameRequired: true,
      idCardNoRequired: true,
      schoolNameRequired: true,
      areaRequired: true,
      loading: false
    };
  },
	computed: {
		...mapGetters({user:"getUser"}),
	},
  props: ["userId", "showTitle","schoolName","role"],
  created: function () {
    if (navigator.onLine) {
      getGrade().then((res) => {
        this.gradeList = res.data;
      });
      getSubject().then((res) => {
        this.subjectList = res.data;
      });
      getuserConfig().then((res) => {
        this.nameRequired = res.data.name;
        this.idCardNoRequired = res.data.idCardNo;
        this.schoolNameRequired = res.data.schoolName;
        this.areaRequired = res.data.address;
      });
      if (this.user && location.href.indexOf("/user") >= 0) {
        getUser(this.user.userId).then((res) => {
          let address = res.data.address;
          res.data.address = [];
          res.data.address.push(
            address.sProvinceCode + "," + address.sProvinceName
          );
          res.data.address.push(address.sCityCode + "," + address.sCityName);
          res.data.address.push(
            address.sCountyCode + "," + address.sCountyName
          );
          this.form.setFieldsValue(res.data);
        });
      }
    }
  },
  mounted(){
    if(this.schoolName){
      this.form.setFieldsValue({schoolName:this.schoolName})
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapActions(['toClassServer']),
    submit() {
      if (this.loading) return;
      this.form.validateFields((err, value) => {
        if (!err) {
          let address = {};
          this.loading = true;
          if (
            value.address &&
            value.address[0] &&
            value.address[1] &&
            value.address[2]
          ) {
            address.sProvinceName = value.address[0].split(",")[1];
            address.sProvinceCode = value.address[0].split(",")[0];
            address.sCityName = value.address[1].split(",")[1];
            address.sCityCode = value.address[1].split(",")[0];
            address.sCountyName = value.address[2].split(",")[1];
            address.sCountyCode = value.address[2].split(",")[0];
          }
          complete({ ...value, address, userId: this.user.userId })
            .then(() => {
              this.$message.success("操作成功!");
              this.loading = false;
              let user = this.user;
              user.userName = value.name;
              localStorage.user = JSON.stringify(user);
              this.setUser(user);
              if(this.$route.query.redirectUrl){
                this.toClassServer({replace: true, redirectUrl:this.$route.query.redirectUrl})
              }else {
                this.$router.push("/");
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.Complete {
  .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin-bottom: 32px;
    font-weight: 600;
  }
  .icon {
    width: 16px;
    height: 16px;
    &.absolute{
      position: absolute;
      z-index: 1;
      left: 12px;
      top: 2px;
    }
  }
  /deep/ .ant-select-selection--single {
    height: 48px;
    .ant-select-selection__rendered {
      height: 48px;
      line-height: 48px;
      margin-left: 32px;
    }
  }
  /deep/ .ant-cascader-picker-label {
    text-align: left;
    left: 20px;
  }
    /deep/ .ant-cascader-input {
        padding-left: 40px
    }
  .required-word {
    display: none;
  }
  .isRequire {
    position: relative;
    .required-word {
      display: inline;
      color: red;
      position: absolute;
      left: 38px;
      top: -10px;
      z-index: 10;
    }
    /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 33px;
    }
    &.area{
      .required-word{
        top: 14px;
        left: 35px;
      }
      /deep/ .ant-cascader-input.ant-input{
        padding-left: 33px;
      }
      /deep/ .ant-cascader-picker-label{
        padding-left: 33px;
      }
    }
  }
}
/deep/  .default-btn {
    height: 48px;
    width: 336px;
    vertical-align: bottom;
    background: #f73210;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    border: 0;
    cursor: pointer;
    -webkit-app-region: no-drag;
}
/deep/ .ant-form-item-control-wrapper {
    display: inline-block;

    .ant-form-item-control {
        width: 336px;
    }

    input {
        height: 48px;
        width: 336px;
    }
}
</style>
